import http from './http'

class ReturnFile {
  get(id) {
    return http.get(`/admin/trademasters/retorno_bancario/${id}`)
  }

  events(id) {
    return http.get(`/admin/trademasters/retorno_bancario/${id}/registros`)
  }

  upload(payload) {
    let formData = new FormData()

    formData.append('retorno[file]', payload)

    return http.post(`/admin/trademasters/retorno_bancario`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }

  exportReturnFiles(payload) {
    return http.get(`/admin/trademasters/retorno_bancario/excel_listagem`, {
      params: payload,
      responseType: 'arraybuffer',
    })
  }

  exportReturnFilesEvents(id, payload) {
    return http.get(`/admin/trademasters/retorno_bancario/${id}/excel`, {
      params: payload,
      responseType: 'arraybuffer',
    })
  }
}

export default new ReturnFile()
