import { $themeBreakpoints } from '@themeConfig'
import Crud from '@/services/crud'
import Bank from '@/services/bank'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
  },
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  actions: {
    getIndexData({}, params) {
      return Crud.index(params)
        .then((reponse) => {
          return reponse
        })
        .catch((error) => {
          return error
        })
    },
    getBanks({}, params) {
      return Bank.fetch()
        .then((reponse) => {
          return reponse
        })
        .catch((error) => {
          return error
        })
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
}
