import http from './http'

class Assignor {
  getAll() {
    return http.get('/backoffice/cedentes', {
      headers: { accept: 'application/json' },
    })
  }
}

export default new Assignor()
