import http from './http'

class Crud {
  index(payload) {
    return http.get(payload.path, { params: payload.data })
  }

  exportToXlsx(payload) {
    return http.get(payload.path, { params: { ids: payload.data } })
  }
}

export default new Crud()
