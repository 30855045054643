import http from './http'

class User {
  block(payload) {
    return http.post('/admin/usuarios/block', { user: { ids: payload } })
  }

  unblock(payload) {
    return http.post('/admin/usuarios/unblock', { user: { ids: payload } })
  }

  update(payload) {
    return http.put(`/admin/usuarios/${payload.id}`, { user: payload })
  }

  exportUsers(payload) {
    return http.get('/admin/usuarios/exportar', { params: payload, responseType: 'arraybuffer' })
  }
}

export default new User()
