import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Modules
import app from './app'
import auth from './auth'
import admin from './admin'
import common from './common'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    auth,
    admin,
    common,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
  plugins: [createPersistedState()],
})

export default store
export const useStore = () => store
