import http from './http'

class Titulo {
  fetch() {
    return http.get('/backoffice/titulos')
  }

  cancel(payload) {
    return http.post(`/recebiveis/titulos/${payload.id}/cancelar`, payload)
  }

  pay(payload) {
    return http.post(`/recebiveis/titulos/${payload.id}/informar_pagamento`, payload)
  }

  updateDeadline(payload) {
    return http.post(`/recebiveis/titulos/${payload.id}/alogar_prazo`, payload)
  }

  decreaseValue(payload) {
    return http.post(`/recebiveis/titulos/${payload.id}/reduzir_valor`, payload)
  }
  exportTitles(payload) {
    return http.get(`/recebiveis/titulos_xlsx`, { params: payload, responseType: 'arraybuffer'})
  }
}

export default new Titulo()
