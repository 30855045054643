import Assingor from '@/services/assignor'
import Remessa from '@/services/remessa'
import Financier from '@/services/financier'
import Payer from '@/services/payer'
import Operation from '@/services/operation'
import Titulo from '@/services/titulo'
import Occurence from '@/services/occurence'
import Invite from '@/services/invite'
import User from '@/services/user'
import ReturnFile from '@/services/return_file'
import ExceptionFile from '@/services/exception_file'
import Admin from '@/services/admin'
import Rediscount from '@/services/rediscount'

const getAssignors = () => {
  return Assingor.getAll()
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      return error
    })
}

const getRemessa = ({}, payload) => {
  return Remessa.get(payload)
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      return error
    })
}

const getRedesconto = ({}, payload) => {
  return Rediscount.get(payload)
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      return error
    })
}

const sendToSign = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Remessa.sendToSign(payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const sendToPay = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Remessa.sendToPay(payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const cancelRemessa = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Remessa.cancel(payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const exportRemittances = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Remessa.exportRemittances(payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const saveOperation = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Operation.save(payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const updateOperation = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Operation.update(payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const newEntry = ({}, payload) => {
  return Financier.addEntry(payload)
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      return error
    })
}

const newOpening = ({}, payload) => {
  return Financier.addOpening(payload)
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      return error
    })
}

const getFinanciers = ({}, payload) => {
  return Financier.getAll(payload)
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      return error
    })
}

const getFinancier = ({}, payload) => {
  return Financier.get(payload)
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      return error
    })
}

const getEntries = ({}, payload) => {
  return Financier.getEntries(payload)
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      return error
    })
}

const exportCashMovements = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Financier.exportCashMovements(payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const getPayers = ({}, payload) => {
  return Payer.fetch(payload)
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      return error
    })
}

const pay = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Titulo.pay(payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const updateDeadline = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Titulo.updateDeadline(payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const decreaseValue = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Titulo.decreaseValue(payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const cancel = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Titulo.cancel(payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const repurchase = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Occurence.repurchase(payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const openTotalValue = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Occurence.openTotalValue(payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const exportTitles = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Titulo.exportTitles(payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const getOperation = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Operation.get(payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const fetchInvites = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Invite.fetch(payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const sendInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Invite.send(payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const acceptInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Invite.accept(payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const validateInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Invite.validate(payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const createInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Invite.create(payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const updateInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Invite.update(payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const deleteUser = ({}, payload) => {
  return new Promise((resolve, reject) => {
    User.delete(payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const updateUser = ({}, payload) => {
  return new Promise((resolve, reject) => {
    User.update(payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const deleteInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Invite.delete(payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const blockUser = ({}, payload) => {
  return new Promise((resolve, reject) => {
    User.block(payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const unblockUser = ({}, payload) => {
  return new Promise((resolve, reject) => {
    User.unblock(payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const getInviteLink = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Invite.url(payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const exportUsers = ({}, { id, payload }) => {
  return new Promise((resolve, reject) => {
    User.exportUsers(id, payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// ARQUIVOS DE RETORNO
const getReturnFile = ({}, payload) => {
  return new Promise((resolve, reject) => {
    ReturnFile.get(payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const getReturnFileEvents = ({}, payload) => {
  return new Promise((resolve, reject) => {
    ReturnFile.events(payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const uploadReturnFile = ({}, payload) => {
  return new Promise((resolve, reject) => {
    ReturnFile.upload(payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const exportReturnFiles = ({}, payload) => {
  return new Promise((resolve, reject) => {
    ReturnFile.exportReturnFiles(payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const exportReturnFilesEvents = ({}, { id, payload }) => {
  return new Promise((resolve, reject) => {
    ReturnFile.exportReturnFilesEvents(id, payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// ARQUIVOS DE EXCEÇÃO
const getExceptionFile = ({}, payload) => {
  return new Promise((resolve, reject) => {
    ExceptionFile.get(payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const getExceptionFileOperations = ({}, payload) => {
  return new Promise((resolve, reject) => {
    ExceptionFile.operations(payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const getExceptionFileBaixas = ({}, payload) => {
  return new Promise((resolve, reject) => {
    ExceptionFile.baixas(payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
const setExceptionOperationBaixaType = ({}, payload) => {
  return new Promise((resolve, reject) => {
    ExceptionFile.setBaixaType(payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
const setExceptionOperationBaixasType = ({}, payload) => {
  return new Promise((resolve, reject) => {
    ExceptionFile.setBaixasType(payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const uploadExceptionFile = ({}, payload) => {
  return new Promise((resolve, reject) => {
    ExceptionFile.upload(payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const exportExceptionFiles = ({}, payload) => {
  return new Promise((resolve, reject) => {
    ExceptionFile.exportExceptionFiles(payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const exportExceptionFilesOperations = ({}, { id, payload }) => {
  return new Promise((resolve, reject) => {
    ExceptionFile.exportExceptionFilesOperations(id, payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const proccessExceptionFile = ({}, payload) => {
  return new Promise((resolve, reject) => {
    ExceptionFile.proccess(payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const getManualImports = ({}) => {
  return new Promise((resolve, reject) => {
    Admin.systemData()
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const importTrademastersData = ({}) => {
  return new Promise((resolve, reject) => {
    Admin.importTrademastersData()
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const setProcessando = ({}, payload) => {
  return Rediscount.setProcessando(payload)
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      return error
    })
}

const setExecutado = ({}, payload) => {
  return Rediscount.setExecutado(payload)
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      return error
    })
}

const setRejeitado = ({}, payload) => {
  return Rediscount.setRejeitado(payload)
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      return error
    })
}



export default {
  pay,
  cancel,
  newEntry,
  newOpening,
  getPayers,
  sendToPay,
  openTotalValue,
  getEntries,
  fetchInvites,
  exportCashMovements,
  sendToSign,
  getRemessa,
  getRedesconto,
  repurchase,
  getOperation,
  getFinancier,
  getAssignors,
  getFinanciers,
  cancelRemessa,
  saveOperation,
  updateOperation,
  decreaseValue,
  updateDeadline,
  exportTitles,
  exportRemittances,
  acceptInvite,
  validateInvite,
  createInvite,
  sendInvite,
  deleteUser,
  deleteInvite,
  updateInvite,
  blockUser,
  getInviteLink,
  updateUser,
  getReturnFile,
  uploadReturnFile,
  getReturnFileEvents,
  exportReturnFiles,
  exportReturnFilesEvents,
  exportUsers,
  unblockUser,
  getExceptionFile,
  uploadExceptionFile,
  getExceptionFileOperations,
  exportExceptionFiles,
  exportExceptionFilesOperations,
  proccessExceptionFile,
  setExceptionOperationBaixaType,
  setExceptionOperationBaixasType,
  getExceptionFileBaixas,
  getManualImports,
  importTrademastersData,
  setProcessando,
  setExecutado,
  setRejeitado,
}
