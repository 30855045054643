import http from './http'

class Financier {
  getAll() {
    // TODO entender a necessidade de passar o header para esse endpoint funcionar
    return http.get('/backoffice/financiadores', { headers: { accept: 'application/json' } })
  }

  get(payload) {
    // TODO entender a necessidade de passar o header para esse endpoint funcionar
    return http.get(`/backoffice/financiadores/${payload.id}`, {
      headers: { accept: 'application/json' },
    })
  }

  addEntry(payload) {
    // TODO entender a necessidade de passar o header para esse endpoint funcionar
    return http.post(`/backoffice/financiadores/${payload.id}/movimento_caixa_manual`, payload, {
      headers: { accept: 'application/json' },
    })
  }

  getEntries(payload) {
    const params = new URLSearchParams(payload).toString()
    // TODO entender a necessidade de passar o header para esse endpoint funcionar
    return http.get(`/backoffice/financiadores/${payload.id}/movimentos_caixa?${params}`, {
      headers: { accept: 'application/json' },
    })
  }

  exportCashMovements(payload) {
    return http.get(`/backoffice/financiadores/${payload.id}/movimentos_caixa_xlsx`, { params: payload, responseType: 'arraybuffer'})
  }

  addOpening(payload) {
    // TODO entender a necessidade de passar o header para esse endpoint funcionar
    return http.post(`/backoffice/financiadores/${payload.id}/abertura_caixa`, payload, {
      headers: { accept: 'application/json' },
    })
  }
}

export default new Financier()
