import Vue from 'vue'
import { initialAbility } from '@/libs/acl/config'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      async (config) => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      (error) => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      (response) => response,
      async (error) => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          Vue.prototype
            .$swal({
              title: 'A sua sessão expirou',
              text: 'Faça um novo login para continuar utilizando a plataforma.',
              icon: 'warning',
            })
            .then(async (result) => {
              console.log(result)
              await this.logout().then(() => {
                window.location.replace('/login')
              })
            })
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  retryOriginalRequestOnRefresh(originalRequest) {
    const retryOriginalRequest = new Promise((resolve) => {
      this.addSubscriber((accessToken) => {
        // Make sure to assign accessToken according to your response.
        // Check: https://pixinvent.ticksy.com/ticket/2413870
        // Change Authorization header
        const request = { ...originalRequest }
        request.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        resolve(this.axiosIns(request))
      })
    })
    return retryOriginalRequest
  }

  handleRefreshToken() {
    if (!this.isAlreadyFetchingAccessToken) {
      this.isAlreadyFetchingAccessToken = true
      this.refreshToken().then((r) => {
        this.isAlreadyFetchingAccessToken = false

        // Update accessToken in localStorage
        this.setToken(r.data.accessToken)
        this.setRefreshToken(r.data.refreshToken)

        this.onAccessTokenFetched(r.data.accessToken)
      })
    }
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  getUserData() {
    return JSON.parse(localStorage.getItem(this.jwtConfig.storageUserDataKeyName))
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  setUserData(value) {
    localStorage.setItem(this.jwtConfig.storageUserDataKeyName, JSON.stringify(value))
  }

  removeToken() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
  }

  removeRefreshToken() {
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  removeUserData() {
    localStorage.removeItem(this.jwtConfig.storageUserDataKeyName)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  async logout() {
    this.removeToken()
    this.removeRefreshToken()
    this.removeUserData()
    Vue.prototype.$ability.update(initialAbility)
    localStorage.removeItem('vuex')
  }
}
