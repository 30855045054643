import http from './http'

class Auth {
  userData() {
    return http.get('/backoffice/empresas/current')
  }
  
  sendResetPasswordEmail(payload) {
    return http.post('/auth/reset_password', { user: payload })
  }

  validateResetToken(payload) {
    return http.get(`/auth/reset_password/validate/${payload}`)
  }

  newPassword(payload) {
    return http.post(`/auth/reset_password/change/${payload.token}`, { user: payload })
  }

  updatePassword(payload) {
    return http.post(`/auth/change_current_password`, { user: payload })
  }
}

export default new Auth()
