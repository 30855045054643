import http from './http'

class Occurence {
  repurchase(payload) {
    return http.post(`/recebiveis/ocorrencias/${payload.id}/recompra`)
  }

  openTotalValue() {
    return http.get('/recebiveis/ocorrencias/total_em_aberto')
  }
}

export default new Occurence()
