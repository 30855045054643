import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

Vue.use(VueSweetalert2, {
  reverseButtons: true,
  confirmButtonColor: '#c9794a',
  customClass: {
    confirmButton: 'btn btn-outline-secondary bg-light-secondary text-dark font-weight-bolder',
    cancelButton: 'btn btn-outline-secondary bg-white text-dark font-weight-bolder',
  },
})
