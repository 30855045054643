import http from './http'

class Invite {
  fetch() {
    return http.get('/backoffice/convites')
  }

  send(id) {
    return http.post(`/backoffice/convites/${id}/enviar_convite`)
  }

  accept(payload) {
    return http.post('/backoffice/convites/aceitar', { user: payload })
  }

  validate(token) {
    return http.get(`/backoffice/convites/${token}/validar`)
  }

  create(payload) {
    return http.post('/backoffice/convites', { convite: payload })
  }

  update(payload) {
    return http.put(`/backoffice/convites/${payload.id}`, { convite: payload })
  }

  delete(id) {
    return http.delete(`/backoffice/convites/${id}`)
  }

  url(id) {
    return http.get(`/backoffice/convites/${id}/url`)
  }
}

export default new Invite()
