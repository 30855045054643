import http from '@/services/http'
import Auth from '@/services/auth'

const validateResetToken = ({}, token) => {
  return new Promise((resolve, reject) => {
    Auth.validateResetToken(token)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export default {
  validateResetToken,
}
