import http from './http'

class Remessa {
  get(payload) {
    return http.get(`/recebiveis/remessas/${payload}`)
  }

  sendToSign(payload) {
    return http.post(`/recebiveis/remessas/${payload}/enviar_para_assinatura`)
  }

  sendToPay(payload) {
    return http.post(`/recebiveis/remessas/${payload}/informar_pagamento`)
  }

  cancel(payload) {
    return http.post(`/recebiveis/remessas/${payload}/cancelar_remessa`)
  }

  exportRemittances(payload) {
    return http.get(`/recebiveis/remessas_xlsx`, { params: payload, responseType: 'arraybuffer'})
  }
}

export default new Remessa()
