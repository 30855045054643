export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: true,
    isVerticalMenuActive: false,
  },
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
    },
    UPDATE_VERTICAL_MENU_ACTIVE(state, val) {
      state.isVerticalMenuActive = val
    },
  },
}
