import http from './http'

class Operation {
  save(payload) {
    return http.post('/backoffice/operacoes', payload)
  }

  update(payload) {
    return http.put(`/backoffice/operacoes/${payload.id}`, payload)
  }

  get(payload) {
    return http.get(`/backoffice/operacoes/${payload}`)
  }
}

export default new Operation()
