import http from './http'

class Rediscount {

  get(id) {
    return http.get(`/recebiveis/cashu/redesconto/${id}`)
  }

  setProcessando(id) {
    return http.post(`/recebiveis/cashu/redesconto/${id}/marcar_processando`)
  }

  setExecutado(id) {
    return http.post(`/recebiveis/cashu/redesconto/${id}/marcar_executado`)
  }

  setRejeitado(payload) {
    return http.post(`/recebiveis/cashu/redesconto/${payload.id}/marcar_rejeitado`, payload)
  }
}

export default new Rediscount()
